import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/8GFwb8UTNOQ2rbtqc9kk/locations/Y7GXtSFgqD6z5v9Jpd8q'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
        /*
          column
          center
          w="100%"
          bg="transparent"
          style={{
            backdropFilter: 'blur(10px)',
            webkitBackdropFilter: 'blur(10px)',
          }}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          h="70px"
          */
        >
          <CFView row center>
            {/* <CFImage
              h="20px"
              ml="12px"
              src={logoTitle}
              alt="Ike Japanese Restaurant Logo Title"
            /> */}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
        /*
          column
          center
          w="100%"
          h="60px"
          bg="transparent"
          style={{
            backdropFilter: 'blur(10px)',
            webkitBackdropFilter: 'blur(10px)',
          }}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          zIndex={98}
          */
        >
          <CFView row justifyBetween alignCenter w="100%" maxWidth="1400px">
            <CFView row center>
              {/* <CFImage
                h="30px"
                ml="15px"
                src={logoTitle}
                alt="Ike Japanese Restaurant Logo Title"
              /> */}
            </CFView>
            {/* <CFView hover mt="10px">
              <CFLink href={orderingUrl}>
                <CFImage
                  h="70px"
                  src={viewMenu}
                  alt="Ike Japanese Restaurant View Menu Button"
                />
              </CFLink>
            </CFView> */}
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
